import React from 'react';

import { HeadlineView } from '../../headline/component/view';
import InstagramFeed from '../../../../pages/instagramFeed';

import './view.scss';

export const ImplementationsView = () => (
    <section className="implementations">
        <HeadlineView text="Nasze realizacje" />
        <InstagramFeed refId="d6efeaf0c3e70731e763662b049212677558503b" />
    </section>
);
